<template>
  <div class="container__" id="patents">
    <div class="_insideContainer">
      <Title
        type="light"
        :tag="$t('aboutus.patent.tag')"
        :title="$t('news.title2')"
      />
      <List type="light" :list="getList" :animation="true" />
    </div>
  </div>
</template>

<script>
import Title from "../common/Title";
import List from "../common/List";

export default {
  name: "News",
  components: {
    Title,
    List,
  },
  methods: {
    onClick(link) {
      return this.$router.push(this.linkCreator(this.$i18n.locale, link));
    },
    linkCreator: function (locale, route) {
      return "/" + locale + "/" + route.toLowerCase();
    },
  },
  computed: {
    getList: function () {
      return [
        {
          title: this.$i18n.t("news.hamle.title"),
          buttonText: this.$t("aboutus.patent.buttonText"),
          link: "news/hamle",
          tag: ["oşinografi", "yerlieknoloji"],
        },
      ];
    },
  },
};
</script>

<style scoped>
.container__ {
  padding-top: 50px;
  max-width: 1900px;
  width: 100%;
  padding-left: 5%;
  padding-right: 5%;
  background-color: white;
}
</style>
